import axios from "axios";
import store from "@/store";
import { MessageBox } from 'element-ui';
axios.interceptors.request.use(
    (config)=> {
     if (
       !config.url.includes('https://restapi.amap.com/v3/ip')&&!config.url.includes('https://devapi.qweather.com/v7')
     ) {
       config.headers.token = store.getters.token;
     }
     return config;
   },
    (error)=> {
     return Promise.reject(error);
   }
 );
 // 添加响应拦截器
 axios.interceptors.response.use(
    (response)=> {
     // 对响应数据进行处理
     if (response.data.code == 403) {
        MessageBox.confirm("登录状态已过期，请重新登录", "系统提示", {
         confirmButtonText: "重新登录",
         cancelButtonText: "取消",
         type: "warning",
       })
         .then(() => {
            store.dispatch("user/resetToken").then(() => {
             location.reload();
           });
         })
         .catch(() => {
           // isRelogin.show = false;
         });
     }else{
       return response;
     }
   },
    (error)=> {
     // 对响应错误进行处理
     return Promise.reject(error);
   }
 );
 export default axios