import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from '@/views/HomeScreen'
// import EnergyUse from '@/views/EnergyUse'
// import DeviceInformation from '@/views/DeviceInformation'
// import HazardousWaste from '@/views/HazardousWaste'
// import OperationSystem from '@/views/OperationSystem'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeScreen
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/deviceinformation',
    name: 'deviceinforation',
    component: () => import('../views/DeviceInformation.vue')
  },
  {
    path: '/homeEquipment',
    name: 'homeEquipment',
    component: () => import('../views/HomeEquipment.vue')
  },
  {
    path: '/energyuse',
    name: 'energyuse',
    component: () => import('../views/EnergyUse.vue')
  },
  {
    path: '/operationsystem',
    name: 'operationsystem',
    component: () => import('../views/OperationSystem.vue')
  },
  {
    path: '/hazardouswaste',
    name: 'hazardouswaste',
    component: () => import('../views/HazardousWaste.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
