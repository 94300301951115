<template>
  <div class="container" id="container">
    <!-- style="height: 45%;" -->
    <el-row>
      <el-col :span="24">
        <div
          class="head"
          style="height: 6vh; background-color: rgb(255, 255, 255)"
        >
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            style="height: 6vh"
          >
            <el-submenu index="1" class="pro" style="height: 6vh">
              <template slot="title"></template>
              <el-menu-item @click="handleInfo"
                >{{ $t("button.Equipment") }}
              </el-menu-item>
              <!-- <el-menu-item @click="handleUse">能源使用情况</el-menu-item> -->
              <el-menu-item @click="handlePlan">{{
                $t("button.waste")
              }}</el-menu-item>
              <el-menu-item @click="handleTask">{{
                $t("button.Operation")
              }}</el-menu-item>
              <el-menu-item @click="handleEquipment">{{
                $t("button.Equipmentall")
              }}</el-menu-item>
            </el-submenu>
          </el-menu>
          <div class="project" style="width: 50%">{{ $t("button.home") }}</div>
          <!-- <div class="content" >
          </div> -->
          <div style="display: flex; align-items: center">
            <!-- <iframe
              scrolling="no"
              src="https://tianqiapi.com/api.php?style=te&skin=pitaya"
              frameborder="0"
              width="200"
              height="20"
              allowtransparency="true"
            ></iframe> -->
            <div style="margin-right: 30px">
              <img
                src="../assets/logo1.png"
                style="width: 100%; height: 6vh"
                @click="tolink"
              />
            </div>
            <!-- <Weather v-if="!lang" style="" :isShow="true"></Weather> -->
            <div style="font-size: 14px; color: #333">
              {{
                $i18n.locale == "zh"
                  ? time.txt[2]
                  : daysOfWeek[new Date().getDay()]
              }}
              <span style="margin-left: 10px">{{
                $i18n.locale == "zh"
                  ? time.txt[0]
                  : new Date().toLocaleDateString("en-US", options)
              }}</span
              ><span style="margin-left: 10px">{{ time.txt[1] }}</span>
            </div>
            <lang-select style="margin-left: 10px" />
            <div style="margin-left: 10px">
              <img
                src="../assets/logout.png"
                style="width: 20px; height: 20px"
                @click="logout"
              />
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="info">
          <ul>
            <li style="font-weight: 700; font-size: 18px">
              {{ $t("HomeScreen.information.Device") }}
            </li>
            <!-- <li style="font-size:12px;color: rgb(121, 114, 114);">Equipment and facilities information</li> -->
          </ul>
          <div
            class="count"
            style="
              width: 100%;
              height: 25vh;
              background: rgb(255, 255, 255);
              margin-left: 15px;
              display: flex;
            "
          >
            <div class="arrange" style="width: 50%">
              <div class="equip">
                <img src="../assets/icon1.png" alt="" />
                <div style="margin-left: 10%">
                  <li>{{ $t("HomeScreen.information.Total") }}</li>
                  <li>
                    <span v-for="(row, index) in rows" :key="index">{{
                      row.device_total
                    }}</span
                    ><span
                      style="margin-left: 10px; font-size: 20px; color: black"
                      >{{ $t("HomeScreen.information.tower") }}</span
                    >
                  </li>
                </div>
              </div>
              <div class="sum">
                <img src="../assets/icon3.png" alt="" />
                <div style="margin-left: 10%">
                  <li>{{ $t("HomeScreen.information.TotalSpare") }}</li>
                  <li>
                    <span>{{ statisticsData.reducerCount }}</span
                    ><span
                      style="margin-left: 10px; font-size: 20px; color: black"
                      >{{ $t("HomeScreen.information.individual") }}</span
                    >
                  </li>
                </div>
              </div>
            </div>
            <div class="arrange" style="width: 50%">
              <div class="plan">
                <img src="../assets/icon2.png" alt="" />
                <div style="margin-left: 10%">
                  <li>{{ $t("HomeScreen.information.Active") }}</li>
                  <li>
                    <span>{{ device_statis.enable_count }}</span
                    ><span
                      style="margin-left: 10px; font-size: 20px; color: black"
                      >{{ $t("HomeScreen.information.tower") }}</span
                    >
                  </li>
                </div>
              </div>
              <div class="waste">
                <img src="../assets/icon4.png" alt="" />
                <div style="margin-left: 10%">
                  <li>{{ $t("HomeScreen.information.Spent") }}</li>
                  <li>
                    <span v-for="(row, index) in rows" :key="index">{{
                      row.used_count
                    }}</span
                    ><span
                      style="margin-left: 10px; font-size: 20px; color: black"
                      >{{ $t("HomeScreen.information.individual") }}</span
                    >
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="handle">
          <ul>
            <li style="font-weight: 700; font-size: 18px">
              {{ $t("HomeScreen.Energy.management") }}
            </li>
            <!-- <li style="font-size:12px;">Energy management</li> -->
          </ul>
        </div>
        <div class="order" style="width: 100%; height: 25vh">
          <div
            class="power"
            style="
              width: 40%;
              background: rgb(33, 62, 223);
              margin-left: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-left: 3%;
            "
          >
            <div
              style="
                color: white;
                display: flex;
                align-items: center;
                justify-content: start;
              "
            >
              <img src="../assets/dayPower.png" style="width: 20%" />
              <div style="margin-left: 20px">
                <div style="font-size: 16px">
                  {{ $t("HomeScreen.Energy.Currentday") }}
                </div>
                <div
                  style="
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                  "
                >
                  <span style="font-size:1.85vw">{{ listData[0].sum }}</span
                  >&nbsp;&nbsp;kWh
                </div>
              </div>
            </div>
            <div
              style="
                color: white;
                display: flex;
                align-items: center;
                justify-content: start;
                margin-top: 30px;
              "
            >
              <img src="../assets/monthPower.png" style="width: 20%" />
              <div style="margin-left: 20px">
                <div style="font-size: 16px">
                  {{ $t("HomeScreen.Energy.Monthlytotal") }}
                </div>
                <div
                  style="
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                  "
                >
                  <span style="font-size:1.85vw">{{ listData[1].sum }}</span
                  >&nbsp;&nbsp;kWh
                </div>
              </div>
            </div>
            <!-- <div class="list_item" v-for="(item, index) in listData" :key="index" >
  <ul style="float: left;margin-left: -20px;">
    
    <li style="font-size: 16px;">{{ item.name }}</li>
    <li style="display: flex;align-items: baseline;justify-content: space-between;"><span style="font-size:30px">{{ item.sum }}</span>kWh
      <div class="echart" :id="item.id" style="width: 100px;height:80px;"></div>
    <span style="font-size:12px;margin-top: -100px;margin-left: -80px;">-25.53%</span><span style="width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 8px solid rgb(255, 255, 255);border-left: 8px solid transparent;border-right: 8px solid transparent;margin-top: -110px;margin-left: 10px;"></span>
  </li>
  </ul>
</div> -->
          </div>
          <!-- <div
            id="electric"
            style="
              width: 56%;
              height: 25vh;
              background-color: rgb(255, 255, 255);
              margin-right: 15px;
            "
          ></div> -->
          <div
            class="count"
            style="
              width: 70%;
              height: 25vh;
              background: rgb(255, 255, 255);
              padding-left: 1%;
              padding-right: 1%;
              margin-right: 15px;
            "
          >
            <p style="font-weight: 700; font-size: 18px">
              {{ $t("HomeScreen.Energystorageinformation") }}
            </p>
            <div style="display: flex; height: 17.5vh">
              <el-col :span="8" class="DLshuju">
                <div style="font-size: 0.74vw">
                  {{ $t("HazardousWaste.chargingpower") }}
                </div>
                <div style="text-align: right; margin-top: 13%">
                  <img src="../assets/chongdian.png" />
                </div>
                <div class="dianlaing">
                  <span>{{ powerdata.charge }}</span
                  >kWh
                </div>
              </el-col>
              <el-col :span="8" class="DLshuju">
                <div style="font-size: 0.74vw">
                  {{ $t("HazardousWaste.dischargepower") }}
                </div>
                <div style="text-align: right; margin-top: 18%">
                  <img src="../assets/fangdiandianliu.png" />
                </div>
                <div class="dianlaing">
                  <span>{{ powerdata.discharge }}</span
                  >kWh
                </div>
              </el-col>
              <el-col :span="8" class="DLshuju">
                <div style="font-size: 0.74vw">
                  {{ $t("HazardousWaste.electricitysavings") }}
                </div>
                <div style="text-align: right; margin-top: 20%">
                  <img src="../assets/shengdian.png" />
                </div>
                <div class="dianlaing">
                  <span>{{ powerdata.economy }}</span
                  >{{ $t("HazardousWaste.yuan") }}
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="box-div">
      <el-col :span="12">
        <div class="task" style="width: 100%">
          <ul>
            <li style="font-weight: 700; font-size: 18px">
              {{ $t("HomeScreen.Profacilityinfor") }}
            </li>
          </ul>
          <div
            class="big"
            style="height: 20vh; display: flex; margin-left: 15px; width: 100%"
          >
            <div class="differ" style="margin-left: 0px">
              <div id="Health" style="width: 100%; height: 20vh"></div>
            </div>
            <div class="differ">
              <div id="rate" style="width: 100%; height: 20vh"></div>
            </div>
          </div>

          <!-- <div
            class="big"
            style="height: 20vh; display: flex; margin-left: 15px; width: 100%"
          >
            <div class="differ" style="margin-left: 0px">
              <p style="margin-left: 6px; margin-top: 0px">
                <span>{{ total_order }}</span
                >&nbsp;&nbsp; {{ $t("HomeScreen.task.individual") }}
              </p>
              <div id="pie1" style="width: 100%; height: 20vh"></div>
            </div>
            <div class="differ">
              <p style="margin-left: 6px; margin-top: 0px">
                <span>{{ the_month_order }}</span
                >&nbsp;&nbsp;{{ $t("HomeScreen.task.individual") }}
              </p>
              <div id="pies" style="width: 100%; height: 20vh"></div>
            </div>
            <div class="differ">
              <p style="margin-left: 10px; margin-top: 0px">
                <span>{{ the_day_order }}</span
                >&nbsp;&nbsp;{{ $t("HomeScreen.task.individual") }}
              </p>
              <div id="piess" style="width: 100%; height: 20vh"></div>
            </div>
          </div> -->
        </div>
      </el-col>
      <el-col :span="12">
        <div class="case">
          <ul>
            <li style="font-weight: 700; font-size: 18px">
              {{ $t("HomeScreen.use.Energy") }}
            </li>
            <!-- <li style="font-size:12px;color: rgb(36, 35, 35);">Energy usage</li> -->
          </ul>
          <div
            class="use"
            style="
              height: 20vh;
              background-color: rgb(255, 255, 255);
              margin-left: 30px;
              margin-right: 15px;
            "
          >
            <div class="close">
              <img src="../assets/nengyuan2.png" alt="" />
              <ul class="give">
                <li style="font-weight: 600">
                  {{ $t("HomeScreen.use.Totalgenerated") }}
                </li>
                <li>
                  <span style="font-size:1.5vw">{{ Powertotal }}</span
                  >&nbsp;&nbsp;kWh
                </li>
              </ul>
            </div>
            <div class="close">
              <img src="../assets/jydl.png" alt="" />
              <ul class="give">
                <li style="font-weight: 600">
                  {{ $t("HazardousWaste.electricitysavings") }}
                </li>
                <li>
                  <span style="font-size:1.5vw">{{ leijiL }}</span
                  >&nbsp;&nbsp;{{ $t("HazardousWaste.yuan") }}
                </li>
              </ul>
            </div>
            <div class="open">
              <img src="../assets/nengyuan3.png" alt="" />
              <ul class="put">
                <li style="font-weight: 600">
                  {{ $t("HomeScreen.use.COreduction") }}
                </li>
                <li>
                  <span style="font-size:1.5vw">{{ reduceCo2 }}</span
                  >&nbsp;&nbsp;{{ $t("HomeScreen.use.T") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 35%" class="box-div">
      <el-col :span="12">
        <!-- <div
          id="change"
          style="
            width: 100%;
            height: 310px;
            background-color: rgb(253, 253, 253);
            margin-top: 15px;
            margin-left: 15px;
          "
        ></div> -->
        <li
          style="
            font-weight: 700;
            font-size: 18px;
            margin-left: 40px;
            margin-top: 15px;
          "
        >
          {{ $t("HomeScreen.task.Operation") }}
        </li>
        <div
          class="big"
          style="
            height: 260px;
            display: flex;
            margin-left: 15px;
            width: 100%;
            margin-top: 15px;
          "
        >
          <div class="differ" style="margin-left: 0px">
            <p style="margin-left: 6px; margin-top: 0px">
              <span>{{ total_order }}</span
              >&nbsp;&nbsp; {{ $t("HomeScreen.task.individual") }}
            </p>
            <div id="tasksTotal" style="width: 100%; height: 260px"></div>
          </div>
          <div class="differ">
            <p style="margin-left: 6px; margin-top: 0px">
              <span>{{ the_month_order }}</span
              >&nbsp;&nbsp;{{ $t("HomeScreen.task.individual") }}
            </p>
            <div id="monthTotal" style="width: 100%; height: 260px"></div>
          </div>
          <div class="differ" style="background-color: #fff">
            <p style="margin-left: 10px; margin-top: 0px">
              <span>{{ the_day_order }}</span
              >&nbsp;&nbsp;{{ $t("HomeScreen.task.individual") }}
            </p>
            <div id="dayTask" style="width: 100%; height: 260px"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div
          id="stored"
          style="
            height: 310px;
            background-color: #fff;
            margin-left: 30px;
            margin-top: 15px;
            margin-right: 15px;
          "
        ></div>
      </el-col>
      <!-- <el-col :span="4"> -->
      <!-- <div class="yard">
  <h3>扫二维码了解更多</h3>
 <img src="../assets/erweima.png" alt=""  style="width:200px;height: 200px;border-radius: 15px;">
</div> -->
      <!-- </el-col> -->
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
// import axios from "axios";
import axios from '@/utils/http'
// import { vMiniWeather } from 'vue-mini-weather'
import { formatDate } from "@/utils/formatTime";
import Weather from "@/components/weather.vue";
export default {
  name: "HomeView",
  components: {
    Weather,
  },
  data() {
    return {
      list: [1646, 12345, 5526, 5976],
      listData: [
        {
          id: "chart1",
          name: this.$t("HomeScreen.Energy.Currentday"),
          sum: 1646,
        },
        {
          id: "chart2",
          name: this.$t("HomeScreen.Energy.Monthlytotal"),
          sum: 12345,
        },
        // {
        //   id: 'chart3',
        //   name: '今日用电量',
        //   sum: 5526
        // },
        // {
        //   id: 'chart4',
        //   name: '本月用电量',
        //   sum: 5976

        // }
      ],
      // counts: [],
      // items: [],
      rows: [],
      completed: [],
      incomplete: [],
      activeIndex: "1",
      items: [],
      counts: [],
      the_month_order: "",
      total_order: "",
      the_day_order: "",
      Powertotal: "",
      reduceCo2: "",
      thisYearDetailPower: [],
      weekDateDeatil: [],
      weekDate: [],
      time: {
        txt: [],
        fun: null,
      },
      value: null, //消息
      timer: null, //定时器
      daysOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      options: { year: "numeric", month: "long", day: "numeric" },
      lang: false,
      statisticsData: "",
      timePage: null,
      device_statis: {},
      powerdata: [],
      leijiL:''
    };
  },
  computed: {
    // lang(){
    //   console.log(localStorage.getItem("languageSet")=='en');
    //  return localStorage.getItem("languageSet")=='en';
    // }
  },
  // 轮询-
  destroyed() {
    //离开页面是销毁
    clearInterval(this.timer);
    this.timer = null;
  },
  beforeRouteLeave(to, from, next) {
    // 在路由离开前清除定时器
    // console.log("我离开了");
    // window.clearInterval(this.timePage);
    // this.timePage = null;
    this.stop();
    next(); // 必须调用next()来resolve这个钩子
  },
  beforeRouteEnter(to, from, next) {
    // 在路由进入前，确保组件已创建
    next((vm) => {
      // 组件实例现在可用，创建定时器
      // 30s跳转页面
      // vm.starttimePage();
      // vm.setRequest();
      vm.GetMsgNum(); //初始化
      // 实现轮询
      vm.timer = window.setInterval(() => {
        setTimeout(vm.GetMsgNum(), 0);
      }, 10000);
    });
  },
  created() {
    // this.GetMsgNum(); //初始化
    // // 实现轮询
    // this.timer = window.setInterval(() => {
    //   setTimeout(this.GetMsgNum(), 0);
    // }, 10000);
  },
  mounted() {
    // this.getItem()
    // this.drawElectric();
    // this.drawPie()
    // this.drawPies()
    // this.drawPiess()
    // this.drawStored()
    // this.getRows();
    // this.getPower();
    this.initTime();
  },
  watch: {
    "$i18n.locale": {
      handler(val, old) {
        if (val == "en") {
          this.lang = true;
        } else {
          this.lang = false;
        }
        //监听语言的变化，每次变动都要重新渲染图表
        var ss = document.getElementById("container");
        if (!ss) {
          // 如果DOM元素不存在，清除定时器并返回
          return;
        }
        // this.drawElectric();
        this.getItem();
        this.drawTasksTotal();
        this.drawmonthTotal();
        this.drawdayTask();
        // this.drawChange();
        this.drawStored();
        this.drawHealth();
        this.drawRate();
      },
      // deep: true,
    },
    $route(to, from) {
      // this.drawElectric();
      this.getItem();
      this.drawTasksTotal();
      this.drawmonthTotal();
      this.drawdayTask();
      // this.drawChange();
      this.drawStored();
      this.drawHealth();
      this.drawRate();
      // console.log(to, from);
      // window.clearInterval(this.timePage);
      // this.timePage = null;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      location.reload();
    },
    starttimePage() {
      this.timePage = window.setInterval(() => {
        this.handleInfo();
      }, 30000);
    },
    tolink() {
      window.open("https://www.chuilian-tech.cn/");
    },
    drawHealth() {
      const chartDom = document.getElementById("Health");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const Xdate12 = [
        {
          value: this.statisticsData.healthCount,
          name: this.$t("HomeEquipment.Energy.health"),
        },
        {
          value: this.statisticsData.slightCount,
          name: this.$t("HomeEquipment.Energy.slight"),
        },
        {
          value: this.statisticsData.attentionCount,
          name: this.$t("HomeEquipment.Energy.focus"),
        },
        {
          value: this.statisticsData.faultCount,
          name: this.$t("HomeEquipment.Energy.seriousness"),
        },
      ];
      const option = {
        title: {
          text: this.$t("HomeEquipment.Energy.Healthproportion"),
          subtextStyle: {
            fontSize: "30",
            // textAlign: 'center', // 副标题水平居中
            // textVerticalAlign: 'center' // 副标题垂直居中
          },
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          width: this.$i18n.locale == "zh" ? "150" : "200",
          left: "left",
          top: "20%",
        },
        // graphic: {
        //   //设置中心图片及文字
        //   elements: [
        //     {
        //       type: "text", //通过不同top值可以设置上下显示
        //       left: "58%",
        //       top: "38%",
        //       style: {
        //         text: this.$t("HomeEquipment.Energy.Healthproportion"),
        //         textAlign: "center",
        //         fill: "#000", //文字的颜色
        //         width: 30,
        //         height: 30,
        //         fontSize: 16,
        //         fontWeight: "bold",
        //         color: "#6c6c6c",
        //         fontFamily: "Microsoft YaHei",
        //       },
        //     },
        //   ],
        // },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["70%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "left",
              borderRadius: "100%",
            },

            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#2b75ed", "#FED55A", "#ff692a", "#D9272D"], // 设置自定义颜色
            data: Xdate12,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawRate() {
      const chartDom = document.getElementById("rate");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const Xdate = [
        {
          value: this.statisticsData.runStateCount,
          name: this.$t("HomeEquipment.Energy.Runnumber"),
        },
        {
          value: this.statisticsData.stopStateCount,
          name: this.$t("HomeEquipment.Energy.Stopnumber"),
        },
        {
          value: this.statisticsData.unknownStateCount,
          name: this.$t("HomeEquipment.Energy.Unknownumber"),
        },
      ];
      const option = {
        title: {
          text: this.$t("HomeEquipment.Energy.Operatingrate"),
          subtextStyle: {
            fontSize: "30",
            // textAlign: 'center', // 副标题水平居中
            // textVerticalAlign: 'center' // 副标题垂直居中
          },
          left: "left",
        },
        // graphic: {
        //   //设置中心图片及文字
        //   elements: [
        //     {
        //       type: "text", //通过不同top值可以设置上下显示
        //       left: "60%",
        //       top: "38%",
        //       style: {
        //         text: this.$t("HomeEquipment.Energy.Operatingrate"),
        //         textAlign: "center",
        //         fill: "#000", //文字的颜色
        //         width: 30,
        //         height: 30,
        //         fontSize: 16,
        //         fontWeight: "bold",
        //         color: "#6c6c6c",
        //         fontFamily: "Microsoft YaHei",
        //       },
        //     },
        //   ],
        // },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          width: this.$i18n.locale == "zh" ? "150" : "200",
          left: "left",
          top: "20%",
        },

        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["70%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#2b75ed", "#D9272D", "#e9e9e9"], // 设置自定义颜色
            data: Xdate,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 轮询-------------
    GetMsgNum() {
      this.getPower();
      this.getRows();
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 轮询结束-------------
    initTime() {
      this.time.txt = formatDate(new Date(), "YYYY-mm-dd HH:MM WWW").split(" ");
      this.time.fun = setInterval(() => {
        this.time.txt = formatDate(new Date(), "YYYY-mm-dd HH:MM WWW").split(
          " "
        );
      }, 1000);
    },

    getPower() {
      axios
        .get(
          "https://guhring-solar-server.damai-tech.com/statPower/generatingCapacity"
        )
        .then((res) => {
          //  console.log(res.data.data);
          this.listData = [
            {
              id: "chart1",
              name: this.$t("HomeScreen.Energy.Currentday"),
              sum: res.data.data.today,
              images: "dayPower",
            },
            {
              id: "chart2",
              name: this.$t("HomeScreen.Energy.Monthlytotal"),
              sum: res.data.data.thisMonth,
              iamges: "monthPower",
            },
          ];
          this.Powertotal = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get("https://guhring-solar-server.damai-tech.com/statPower/reduceCo2")
        .then((res) => {
          //  console.log(res.data.data);

          this.reduceCo2 = res.data.data.reduceCo2;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          "https://guhring-solar-server.damai-tech.com/statPower/thisYearDetail"
        )
        .then((res) => {
          //  console.log(res.data.data);
          this.thisYearDetailPower = res.data.data;
          this.$nextTick(() => {
            this.drawStored();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          "https://guhring-solar-server.damai-tech.com/statPower/pastWeekDetail"
        )
        .then((res) => {
          this.weekDateDeatil = res.data.data.y;
          this.weekDate = res.data.data.x;
          this.$nextTick(() => {
            // this.drawElectric();
          });
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          "https://startupfactory-server.chuilian-tech.cn/system/info/getHomepageStatisticsData",
        )
        .then((res) => {
          // if (res.data.code == 403) {
          //   this.$confirm("登录状态已过期，请重新登录", "系统提示", {
          //     confirmButtonText: "重新登录",
          //     cancelButtonText: "取消",
          //     type: "warning",
          //   })
          //     .then(() => {
          //       this.$store.dispatch("user/resetToken").then(() => {
          //         location.reload();
          //       });
          //     })
          //     .catch(() => {
          //       // isRelogin.show = false;
          //     });
          // } else {
            this.statisticsData = res.data.data;
            this.$nextTick(() => {
              this.drawHealth();
              this.drawRate();
            });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          "https://smart-factory.chuilian-tech.cn/api/v2/Statisticsinfo/getMaintenanceinfo?abbr=GUH&token=123456"
        )
        .then((res) => {
          this.device_statis = res.data.data.device_statis;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          "https://guhring-energy-storager-server.damai-tech.com/system/info/power"
        )
        .then((res) => {
          // console.log(res.data.data);
          this.powerdata = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(
          "https://guhring-solar-server.damai-tech.com/statPower/totalEarning"
        )
        .then((res) => {
          // console.log(res);
          this.leijiL = res.data.data.earning;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getItem() {
      // this.listData.forEach((elem) => {
      //   const chartDom = document.getElementById(elem.id);
      //   if (!chartDom) {
      //     // 如果DOM元素不存在，清除定时器并返回
      //     this.stop();
      //     return;
      //   }
      //   const myChart = echarts.init(chartDom);
      //   const option = {
      //     xAxis: {
      //       type: "category",
      //       data: [],
      //       axisLine: {
      //         show: false, // 隐藏x轴线条
      //       },
      //       splitLine: {
      //         show: true, // 隐藏X轴分隔线
      //       },
      //       axisTick: {
      //         show: false, // 隐藏刻度线
      //       },
      //     },
      //     yAxis: {
      //       type: "value",
      //       axisLine: {
      //         show: false, // 隐藏y轴线条
      //       },
      //       splitLine: {
      //         show: false, // 隐藏Y轴分隔线
      //       },
      //       axisLabel: {
      //         show: false, // 隐藏y轴上的数值标签
      //       },
      //     },
      //     series: [
      //       {
      //         data: [820, 932, 901, 334, 1290, 1330, 1320],
      //         type: "line",
      //         smooth: true,
      //         symbol: "none",
      //         color: "white",
      //       },
      //     ],
      //   };
      //   myChart.setOption(option);
      //   window.addEventListener("resize", function () {
      //     myChart.resize();
      //   });
      // });
    },
    drawElectric() {
      const chartDom = document.getElementById("electric");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const option = {
        // title: {
        //   padding: 20,
        //   text: '本月用电量',
        //   textStyle: {
        //     fontSize: '15',
        //     fontWeight: '700'
        //   }
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [this.$t("HomeScreen.Energy.Recenttotal")],
          left: "0%",
          padding: 20,
          textStyle: {
            fontSize: "15",
            fontWeight: "700",
          },
        },
        grid: {
          left: "3%",
          right: "10%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            margin: 5, // 通过调整 axisLabel 属性的 margin 值，将文本标签向左移动
            // align: 'right' // 将文本标签左对齐
          },
          axisLine: {
            show: false, // 隐藏x轴线条
          },
          splitLine: {
            show: false, // 隐藏X轴分隔线
          },
          axisTick: {
            show: false, // 隐藏刻度线
            alignWithLabel: true, // true：标签位于刻度线正下方；false：标签位于2个刻度线中间
          },
          type: "category",
          boundaryGap: false,
          // offset: 50,
          data: this.weekDate,
        },
        yAxis: {
          axisLabel: {
            inside: false, // 将标签显示在外部
            margin: 35, // 调整标签与轴线之间的距离
          },
          axisLine: {
            show: false, // 隐藏y轴线条
          },
          splitLine: {
            show: false, // 隐藏Y轴分隔线
          },
          type: "value",
        },
        series: [
          {
            type: "line",
            // position: 'left',
            data: this.weekDateDeatil,
            symbol: "circle",
            symbolSize: 10,
            boundaryGap: true,
            tooltip: {
              trigger: "axis",
            },
            // symbolOffset: [-5, "0"],
            // 设置 symbolOffset 属性向上移动一定距离
          },
          {
            name: this.$t("HomeScreen.Energy.Recenttotal"),
            stack: "Total",
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "bar",
            // barWidth: '80%',
            barWidth: 60,
            barGap: "10%",
            itemStyle: {
              color: "transparent",
              // 设置柱状颜色为透明
              shadowBlur: 10, // 设置阴影的模糊度
              shadowColor: "rgba(0, 0, 0, 0.3)", // 设置阴影的颜色
            },
            showBackground: true,
            backgroundStyle: {
              color: "#e9eeef",
            },
            tooltip: {
              trigger: "axis",
              show: false,
            },
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawTasksTotal() {
      const chartDom = document.getElementById("tasksTotal");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const Xdate12 = [
        {
          value: this.counts.total_order.repair,
          name: this.$t("HomeScreen.task.Repair"),
        },
        {
          value: this.counts.total_order.maintenance,
          name: this.$t("HomeScreen.task.Maintenance"),
        },
        {
          value: this.counts.total_order.patrol,
          name: this.$t("HomeScreen.task.Inspection"),
        },
        {
          value: this.counts.total_order.modification,
          name: this.$t("HomeScreen.task.Others"),
        },
      ];
      const option = {
        title: {
          text: this.$t("HomeScreen.task.Total"),
          subtextStyle: {
            fontSize: "30",
            // textAlign: 'center', // 副标题水平居中
            // textVerticalAlign: 'center' // 副标题垂直居中
          },
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orinet: "vertical",
          icon: "circle",
          top: "65%",
          width: this.$i18n.locale == "zh" ? "150" : "200",
          left: "left",
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["30%", "45%"],
            center: ["70%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "left",
              borderRadius: "100%",
            },

            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#ff692a", "#2b75ed", "#ffb330", "#e9e9e9"], // 设置自定义颜色
            data: Xdate12,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawmonthTotal() {
      const chartDom = document.getElementById("monthTotal");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const Xdate12 = [
        {
          value: this.counts.the_month_order.repair,
          name: this.$t("HomeScreen.task.Repair"),
        },
        {
          value: this.counts.the_month_order.maintenance,
          name: this.$t("HomeScreen.task.Maintenance"),
        },
        {
          value: this.counts.the_month_order.patrol,
          name: this.$t("HomeScreen.task.Inspection"),
        },
        {
          value: this.counts.the_month_order.modification,
          name: this.$t("HomeScreen.task.Others"),
        },
      ];
      const option = {
        title: {
          text: this.$t("HomeScreen.task.Monthly"),
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orinet: "vertical",
          icon: "circle",
          top: "65%",
          width: this.$i18n.locale == "zh" ? "150" : "200",
          left: "left",
        },

        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["30%", "45%"],
            center: ["70%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "left",
              borderRadius: "100%",
            },

            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#ff692a", "#2b75ed", "#ffb330", "#e9e9e9"], // 设置自定义颜色
            data: Xdate12,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawdayTask() {
      const chartDom = document.getElementById("dayTask");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const Xdate = [
        {
          value: this.counts.the_day_order.repair,
          name: this.$t("HomeScreen.task.Repair"),
        },
        {
          value: this.counts.the_day_order.maintenance,
          name: this.$t("HomeScreen.task.Maintenance"),
        },
        {
          value: this.counts.the_day_order.patrol,
          name: this.$t("HomeScreen.task.Inspection"),
        },
        {
          value: this.counts.the_day_order.modification,
          name: this.$t("HomeScreen.task.Others"),
        },
      ];
      const option = {
        title: {
          text: this.$t("HomeScreen.task.Currentday"),
          textStyle: {
            // fontSize: 20
          },
          // subtext: '41420个',
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orinet: "horizontal",
          icon: "circle",
          // x: '50%',
          top: "65%",
          width: this.$i18n.locale == "zh" ? "150" : "200",
          left: "left",
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["30%", "45%"],
            center: ["70%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#ff692a", "#2b75ed", "#ffb330", "#e9e9e9"], // 设置自定义颜色
            data: Xdate,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawChange() {
      const chartDom = document.getElementById("change");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const xData = this.items.map((item) => {
        return item.date;
      });
      const dataList = this.items.map((item) => {
        return item.completed;
      });
      const dataLists = this.items.map((item) => {
        return item.incomplete;
      });
      const option = {
        color: ["#80FFA5", "#00DDFF"],
        title: {
          text: this.$t("HomeScreen.task.TaskStatistics"),
          top: "5%",
          left: "4%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [
            this.$t("HomeScreen.task.completed"),
            this.$t("HomeScreen.task.uncompleted"),
          ],
          width: 100,
          right: "8%",
          top: "3%",
        },

        grid: {
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLine: {
            show: false, // 隐藏x轴线条
          },
          splitLine: {
            show: false, // 隐藏X轴分隔线
          },
          axisTick: {
            show: false, // 隐藏刻度线
          },
          type: "category",
          boundaryGap: false,

          data: xData,
          axisLabel: {
            textStyle: {
              fontSize: 10,
            },
          },
        },
        yAxis: {
          // min: 0,
          // max: 10,
          // axisLabel: {
          //   formatter: function (value) {
          //     if (value % 2 === 0) {
          //       return value
          //     } else {
          //       return ''
          //     }
          //   }

          // },
          type: "value",
          axisLine: {
            show: false, // 隐藏y轴线条
          },
          splitLine: {
            show: false, // 隐藏Y轴分隔线
          },
        },
        series: [
          {
            name: this.$t("HomeScreen.task.completed"),
            type: "line",
            stack: "Total",
            smooth: true,
            color: "#fb95d5",
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fb95d5",
                },
                {
                  offset: 1,
                  color: "#94c0fb",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            data: dataList,
          },
          {
            name: this.$t("HomeScreen.task.uncompleted"),
            type: "line",
            stack: "Total",
            color: "#94c0fb",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(0, 221, 255)",
                },
                {
                  offset: 1,
                  color: "rgb(77, 119, 255)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            // [18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18]
            data: dataLists,
          },
        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawStored() {
      const chartDom = document.getElementById("stored");
      if (!chartDom) {
        // 如果DOM元素不存在，清除定时器并返回
        this.stop();
        return;
      }
      const myChart = echarts.init(chartDom);
      const dataX = [
        this.$t("HomeScreen.use.January"),
        this.$t("HomeScreen.use.February"),
        this.$t("HomeScreen.use.March"),
        this.$t("HomeScreen.use.April"),
        this.$t("HomeScreen.use.May"),
        this.$t("HomeScreen.use.June"),
        this.$t("HomeScreen.use.July"),
        this.$t("HomeScreen.use.August"),
        this.$t("HomeScreen.use.September"),
        this.$t("HomeScreen.use.October"),
        this.$t("HomeScreen.use.November"),
        this.$t("HomeScreen.use.December"),
      ];
      const option = {
        backgroundColor: "rgb(255,255,255)",
        title: {
          text: this.$t("HomeScreen.use.Yearlyview"),
          top: "5%",
          left: "5%",
          // textStyle: {
          //   fontSize: 15
          // }
        },
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ['数据'],
        //   icon: 'rect',
        //   // width: 100,
        //   right: '8%',
        //   top: '25%',
        //   itemWidth: 8,
        //   itemHeight: 8
        //   // left: 'left'
        // },
        xAxis: {
          type: "category",
          data: dataX,
          axisLabel: {
            textStyle: {
              fontSize: 10,
            },
          },
        },
        yAxis: {
          // min: 0,
          // max: 80000,
          // axisLabel: {
          //   formatter: function (value) {
          //     if (value % 20000 === 0) {
          //       return value
          //     } else {
          //       return ''
          //     }
          //   }

          // },
          type: "value",

          // nameTextStyle: {
          //   fontSize: 15, // 原来的字体大小
          //   color: 'black',
          //   fontWeight: '900'
          // },
          axisLine: {
            show: true, // 隐藏y轴线条
          },
          splitLine: {
            show: false, // 隐藏Y轴分隔线
          },
          axisTick: {
            show: true, // 隐藏刻度线
          },

          // data: [0, 10000, 30000, 50000, 70000]
        },
        grid: {
          left: "10%",
          top: "20%",
          right: "5%",
          bottom: "8%",
          // 其它网格配置项
        },
        series: [
          // {
          //   name: '',
          //   type: 'line',
          //   symbol: 'none',
          //   stack: 'Total',
          //   // right: 'right',
          //   // smooth: true,
          //   color: '#3a83ef',
          //   lineStyle: {
          //     // fontSize: 12
          //     // width: 0,
          //     // borderRadius: 0

          //   }
          // },
          {
            barWidth: 15,
            // barBorderRadius: '20%',
            itemStyle: {
              color: "#3f87f0",
              borderRadius: 20, // 设置圆角半径为5
            },
            // barBorderRadious: 20,
            data: this.thisYearDetailPower,
            type: "bar",
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getRows() {
      axios
        .get(
          "https://smart-factory.chuilian-tech.cn/api/v2/Statisticsinfo/getParkEngineeringinfo?abbr=GUH&token=123456"
        )
        .then((res) => {
          this.rows = res.data.data;
          this.items = res.data.data.task_statistics;
          this.counts = res.data.data.maintenance_task;
          this.the_day_order =
            Number(this.counts.the_day_order.patrol) +
            Number(this.counts.the_day_order.modification) +
            Number(this.counts.the_day_order.maintenance) +
            Number(this.counts.the_day_order.repair);
          this.the_month_order =
            Number(this.counts.the_month_order.patrol) +
            Number(this.counts.the_month_order.modification) +
            Number(this.counts.the_month_order.maintenance) +
            Number(this.counts.the_month_order.repair);
          this.total_order =
            Number(this.counts.total_order.patrol) +
            Number(this.counts.total_order.modification) +
            Number(this.counts.total_order.maintenance) +
            Number(this.counts.total_order.repair);
          // console.log(res.data.data.device_statis)
          // console.log(rows.device_statis.total)
          // console.log(res.data.data.device_statis)
          // console.log(res.data.data.spare_statis.total)
          // console.log(res.data.data.spare_statis.used_count)
          this.$nextTick(() => {
            // this.drawChange();
            this.drawTasksTotal();
            this.drawmonthTotal();
            this.drawdayTask();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleInfo() {
      this.$router.push("/deviceinformation");
    },
    handleUse() {
      // this.$router.push("/energyuse");
      // this.$router.push('/deviceinfromation')
    },
    handleTask() {
      this.$router.push("/operationsystem");
      // this.$router.push('/deviceinfromation')
    },
    handlePlan() {
      this.$router.push("/hazardouswaste");
      // this.$router.push('/deviceinfromation')
    },
    handleEquipment() {
      this.$router.push("/homeEquipment");
    },
  },
};
</script>
<style scoped>
body {
  overflow: hidden;
}
.container {
  /* margin: auto;
  overflow: hidden; */
  /* height: 100%; */
  /* height: calc(100vh - 75px); */
  overflow: hidden;
  background: rgb(241, 239, 239);
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.content {
  margin-left: 500px;
  display: flex;
}
li {
  list-style: none;
}
.head ul li {
  line-height: 80px;
}
.pro {
  background: url(../assets/liebiao.png) no-repeat left;
  padding-left: 45px;
  font-size: 23px;
  color: rgb(146, 133, 133);
}
.project {
  font-size: 23px;
  position: absolute;
  left: 150px;
}
.city {
  background: url(../assets/dwicon.png) no-repeat left;
  background-size: contain;
  padding-left: 30px;
  padding-right: 20px;
}
.weather {
  background: url(../assets/tqicon.png) no-repeat left;
  background-size: contain;
  padding-left: 35px;
}
.count .arrange {
  float: left;
}
.equip span,
.waste span,
.plan span,
.sum span,
.give span,
.put span {
  font-size: 30px;
  color: #0057e9;
}
.equip img,
.plan img,
.sum img,
.waste img {
  width: 50px;
  height: 50px;
  align-self: center;
}
.equip,
.plan,
.sum,
.waste {
  display: flex;
  /* margin: 20px 100px; */
  margin-left: 50px;
  /* margin-top: 20px; */
  align-items: center;
  /* margin-right: 320px; */
  padding-top: 3% !important;
  height: 10vh;
}
.plan,
.waste {
  /* margin-left: 500px; */
}
.power {
  float: left;
}
.order {
  display: flex;
}

.day {
  margin-left: -420px;
}
.day ul li:last-child {
  margin-left: 80px;
  margin-top: -50px;
}
.infor ul li:last-child {
  margin-top: -50px;
  margin-left: 10px;
}
.infor ul li,
.day ul li {
  /* margin-top: 30px; */
  color: white;
  font-size: 5px;
}
.infor ul li span,
.day ul li span {
  font-size: 18px;
  font-weight: 700;
}
.differ {
  width: 100%;
  background: rgb(255, 255, 255);
  margin-left: 17px;
  padding-top: 15px;
  padding-left: 20px;
}
.differ p {
  position: absolute;
  top: 160px;
}
.differ p span {
  font-size: 38px;
}
.differ ul li:last-child {
  margin-top: 30px;
}
.use {
  display: flex;
}

.give li:first-child,
.put li:first-child {
  /* margin-top: -20px; */
  margin-bottom: 10px;
}
.close,
.open {
  width: 33%;
  display: flex;
  align-items: center;
}
.close img,
.open img {
  margin-left: 30px;
  width: 23%;
  /* height: 70px; */
}
.close {
  /* border-right: 0.5px solid rgb(82, 46, 139); */
}
.close ul,
.open ul {
  padding-inline-start: 10px !important;
}
.yard {
  margin: 60px;
}
.yard h3 {
  text-align: center;
}
.list_item ul li {
  color: white;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 6vh !important;
}
.DLshuju {
  background-color: #f4f6f7;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
}
.dainchi {
  margin-top: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 5%;
}
.dainchi .shujuew {
  font-size: 3vw;
  font-weight: 700;
  margin-right: 3px;
}
.dainchi img {
  width: 32px;
  height: 54px;
}
.dianlaing span {
  color: #2461ef;
  font-weight: 700;
  font-size: 1.2vw;
}
.dianlaing {
  text-align: right;
  margin-top: 10%;
  font-size: 0.6vw;
}
</style>
