<template>
  <div>
    <!-- <v-mini-weather
    ref="weather"
    id="weather"
    style="
      margin-right: 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: end;
    "
  >
    <template #default="{ weather, icon }">
      <v-mini-weather-icon :icon="icon" style="width: 6%"></v-mini-weather-icon>
      <span
        >{{ weather.cityname }}&nbsp;&nbsp;{{ weather.weather }}&nbsp;&nbsp;{{
          weather.temp
        }}℃</span
      >
    </template>
  </v-mini-weather> -->
    <div style="font-size: 14px; margin-right: 10px" v-if="showModal">
      {{ local.city }} {{ local.text }} {{ local.temperature }}℃
    </div>
    <div v-if="!showModal">{{ local.temperature }}℃</div>
  </div>
</template>

<script>
import { vMiniWeather, vMiniWeatherIcon } from "vue-mini-weather";
import store from "@/store/index";
import axios from "axios";
export default {
  name: "Weather",
  components: {
    // vMiniWeather,
    // vMiniWeatherIcon,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    isShow:{
      handler(newVal, objVal) {
        this.showModal = newVal;
        // console.log(newVal,12,this.showModal);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      local: {
        // ip地址所在城市
        city: "",
        // 所在城市温度
        temperature: "",
        // 天气类型
        type: "",
        // 时
        hour: "",
        // 分
        minute: "",
        // 月
        month: "",
        // 日
        date: "",
        // 星期几
        day: "",
        // 出勤率echart数据
        text: "",
      },
      // 经纬度信息
      location: {
        lat: null,
        lng: null,
      },
      showModal: false,
    };
  },
  created() {
    // this.getLocalCity();
    this.GetLocation();
  },

  methods: {
    GetLocation() {
      axios({
        url: "https://restapi.amap.com/v3/ip",
        method: "post",
        params: {
          key: "1ebc202d19991bcfb596c718bd60ae4d",
        },
      })
        .then((res) => {
          // console.log(res.data.rectangle);
          this.local.city = res.data.city;
          //   this.getWeathData(res.data.city, res.data.adcode);
          this.getLocalWeather(res.data.rectangle);
        })
        .catch();
    },
    getWeathData(cityName, cityCode) {
      this.cityName = cityName;
      let that = this;
      axios({
        url: "https://restapi.amap.com/v1/weather/weatherInfo",
        method: "GET",
        params: {
          key: "1ebc202d19991bcfb596c718bd60ae4d",
          city: cityCode,
          extensions: "all",
          output: "JSON",
        },
      })
        .then(function (resp) {
          console.log(resp.data);
          //   that.renderWeathDom(resp.data);
        })
        .catch();
    },
    // 获取室外温度
    getLocalCity() {
      var data = {
        key: "TJRBZ-W5J3U-QMPVD-G43KF-7YJHZ-6GFBC",
      };
      var url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
      data.output = "jsonp";
      this.$jsonp(url, data)
        .then((res) => {
          console.log(res);
          this.location = res.result.location;
          // 根据城市，获取当前天气和时间（在此先预留获取天气方法，完成后取消注释）
          //   this.getLocalWeather(this.location)  // 这里就是调用下面的那个
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取当地时间和天气,这里的lng,lat是当前位置的经纬度信息
    getLocalWeather(location) {
      //   console.log(location.split(';')[0].split(',')[0].toFixed(2));
      let aa = location.split(";")[0].split(",")[0];
      let bb = location.split(";")[0].split(",")[1];
      const lng = Math.round(aa * 100) / 100;
      const lat = Math.round(bb * 100) / 100;
      //   console.log(aa,lat);
      axios
        .get(
          `https://devapi.qweather.com/v7/weather/now?location=${lng},${lat}&key=9e5f5acda33042febe01b1a177aa72d4`
        )
        .then((res) => {
          //   console.log(res);
          this.local.temperature = res.data.now.temp;
          this.local.text = res.data.now.text;
        //   console.log(this.local.temperature, this.local.text);
        });
    },
  },
  mounted() {
    // console.log(document.getElementById('weather').innerText);
    // const weather = document.getElementById("weather").innerText;
    // if (weather != "更新中...") {
    //   store.commit("SET_WEATHER", weather);
    // }
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;
    // console.log(latitude, longitude);
    //       // 在这里调用获取天气数据的函数
    //     //   getWeatherData(latitude, longitude);
    //     });
  },
};
</script>